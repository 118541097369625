import {createStore} from 'vuex'
import axios from 'axios'

const state = {
    user: null
}

const getters = {
    isAuthenticated: state => !!state.user,
    user: state => state.user,
}

const actions = {
    async login({commit}, User) {
        await axios.get('/sanctum/csrf-cookie')
        await axios.post('/api/v1/authenticate', User).then(res => {
            commit('setUser', res.data)
        })
    },

    async logout({commit}) {
        let user = null
        commit('logout', user)
    },

    findClient(_, code) {
        return new Promise((resolve, reject) => {
            axios.get(`/api/v1/client/${code}`).then((response) => {
                return resolve(response.data.data)
            }).catch(() => {
                return reject(`Не могу найти клиента с номером: ${code}`)
            })
        })
    },

    async createVisit(_, visit) {
        await axios.post(`/api/v1/client/${visit.client.code}`, visit.form)
    }
}

const mutations = {
    setUser(state, user) {
        state.user = user
    },
    LogOut(state) {
        state.user = null
    },
}

export default createStore({
    state,
    getters,
    actions,
    mutations
})