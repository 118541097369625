<template>
  <div>
    <nav v-if="isAuthenticated" class="navbar navbar-expand-lg navbar-dark bg-dark">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">Квартира95</a>
        <t-g-modal />
      </div>
    </nav>
    <div class="container-fluid">
      <router-view/>
    </div>
  </div>
</template>

<script>
import TGModal from '@/components/TGModal'

export default {
  components: {
    TGModal
  },
  computed: {
    isAuthenticated : function(){ return this.$store.getters.isAuthenticated}
  }
}
</script>