<template>
<div>
  <button class="navbar-toggler" data-bs-toggle="modal" data-bs-target="#tgModal">
    <i class="fa-brands fa-telegram"></i>
  </button>
  <div class="modal fade" id="tgModal" tabindex="-1" aria-labelledby="tgModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-fullscreen">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Телеграм бот</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <img class="rounded mx-auto d-block img-fluid" src="qrcode.png">
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "TGModal"
}
</script>

<style scoped>

</style>