<template>
  <div class="mt-4">
    <h1>Квартира95 | <small class="text-muted">Вход</small></h1>
    <div class="card">
      <div class="card-body">
        <div class="mb-3">
          <label for="email" class="form-label">Email</label>
          <input type="email" class="form-control is-invalid" id="email" v-model="form.email" required>
        </div>
        <div class="mb-3">
          <label for="password" class="form-label">Пароль</label>
          <input type="password" class="form-control" id="password" v-model="form.password" required>
        </div>
        <div class="d-grid gap-2">
          <button class="btn btn-outline-primary" @click="submit()">Войти</button>
        </div>

        <div class="text-center mt-2" v-if="showError">
          <small class="text-danger">Неверный email или пароль.</small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  name: "AuthenticateView",
  data() {
    return {
      form: {
        email: null,
        password: null,
      },
      showError: false
    }
  },
  methods: {
    ...mapActions(['login']),
    async submit() {
      const User = new FormData
      User.append('email', this.form.email);
      User.append('password', this.form.password)

      try {
        await this.login(User)
        this.$router.push('/')
        this.showError = false
      } catch (error) {
        this.showError = true
      }
    }
  }
}
</script>

<style scoped>

</style>