import {createRouter, createWebHistory} from 'vue-router'
import store from '@/store'
import HomeView from '@/views/HomeView.vue'
import AuthenticateView from '@/views/AuthenticateView'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {requiresAuth: true},
    },
    {
        path: '/authenticate',
        name: 'authenticate',
        component: AuthenticateView,
        meta: {guest: true},
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (store.getters.isAuthenticated) {
            next();
            return;
        }
        next('/authenticate');
    } else {
        next();
    }
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.guest)) {
        if (store.getters.isAuthenticated) {
            next('/');
            return;
        }
        next();
    } else {
        next();
    }
});

export default router
