<template>
  <div>
    <div class="card mt-3">
      <div class="card-body">
        <div>
          <label class="form-label">Код клиента</label>
          <div class="input-group">
            <input type="number" class="form-control" aria-label="Код клиента" aria-describedby="code-addon"
                   v-model="code">
            <span class="input-group-text" id="code-addon">
              <i v-if="!isLoading && !error" class="fa-solid fa-heart"></i>
              <i v-if="isLoading" class="fa-solid fa-heart fa-beat" style="--fa-beat-scale: 2.0;"></i>
              <i v-if="!isLoading && error" class="fa-solid fa-heart-crack"></i>
            </span>
            <small v-if="error" class="text-danger mt-3">{{ error }}</small>
          </div>
        </div>
      </div>
    </div>

    <div v-if="success" @click="hideAlert()" class="alert alert-success alert-dismissible fade show mt-3" role="alert">
      Чек успешно отправлен!
    </div>

    <div class="card mt-3" v-if="!isLoading && client && !error">
      <div class="card-header">
        <div class="row">
          <div class="col text-start">{{ client.name }}</div>
          <div class="col text-end">Кешбек - {{ client.discount }}%</div>
        </div>
      </div>
      <div class="card-body">
        <div class="mb-3">
          <label for="amount" class="form-label">Сумма чека</label>
          <div class="input-group">
            <input type="number" class="form-control" id="amount" aria-describedby="amount-addon" v-model="amount">
            <span class="input-group-text" id="amount-addon">руб</span>
          </div>
        </div>

        <div class="form-check form-switch mb-3" v-if="amount && maximumAmountCashback > 0">
          <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" v-model="spend">
          <label class="form-check-label" for="flexSwitchCheckChecked">Списать {{ maximumAmountCashback }}
            рублей?</label>
        </div>

        <div class="table-responsive" v-if="amount && spend">
          <table class="table table-bordered table-sm mb-3">
            <tr>
              <td class="text-start">Сумма чека</td>
              <td class="text-end">{{ amount }} руб.</td>
            </tr>
            <tr>
              <td class="text-start">Скидка</td>
              <td class="text-end">{{ maximumAmountCashback }} руб.</td>
            </tr>
            <tr>
              <td class="text-start">К оплате</td>
              <td class="text-end">{{ totalAmount }} руб.</td>
            </tr>
          </table>
        </div>

        <div class="d-grid gap-2" v-if="amount">
          <button v-if="!isSubmitting" type="button" class="btn btn-outline-dark" @click="submit()">Отправить чек
          </button>
          <button v-if="isSubmitting" type="button" class="btn btn-outline-dark" disabled>Отправляется...</button>
        </div>
      </div>
      <div class="card-footer text-muted">
        Баланс пользователя: {{ client.cashback }} руб.
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  name: 'HomeView',
  data() {
    return {
      code: null,
      client: null,
      isLoading: false,
      isSubmitting: false,
      success: false,
      error: null,
      amount: null,
      spend: false,
    }
  },
  watch: {
    code(code) {
      if (code && code.toString().length < 6) {
        this.client = null
        this.isLoading = false
        this.error = null
      }
      if (code && code.toString().length === 6) this.getClient(code)
    },
  },
  computed: {
    totalAmount() {
      return this.amount - this.maximumAmountCashback
    },
    maximumAmountCashback() {
      const maxAmount = (this.amount / 100 * this.client.max_discount)

      return maxAmount >= this.client.cashback ? this.client.cashback : maxAmount
    }
  },
  methods: {
    ...mapActions(['findClient', 'createVisit']),
    submit() {
      this.isSubmitting = true

      const Form = new FormData
      Form.append('amount', this.amount)
      Form.append('spend', this.spend ? this.maximumAmountCashback : 0)

      try {
        this.createVisit({'client': this.client, 'form': Form}).then(() => {
          this.resetForm()
          this.success = true
        })
      } catch (error) {
        this.error = error
      } finally {
        this.isSubmitting = false
      }
    },
    async getClient(code) {
      this.isLoading = true

      try {
        this.findClient(code).then(client => {
          this.client = client
        }).catch(e => {
          this.error = e
        })
      } finally {
        this.isLoading = false
      }
    },
    resetForm() {
      this.code = null
      this.client = null
      this.isLoading = false
      this.error = null
      this.amount = null
      this.spend = false
    },
    hideAlert() {
      this.success = false
      this.error = null
    }
  }
}
</script>
