import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import Notifications from '@kyvg/vue3-notification'


axios.defaults.withCredentials = true
axios.defaults.baseURL = 'https://discountster.ru';

axios.interceptors.response.use(undefined, function (error) {
  if (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
  
        originalRequest._retry = true;
        store.dispatch('logout')
        return router.push('/authenticate')
    }
  }
})

createApp(App).use(store).use(router).use(Notifications).mount('#app')
